import {
  Box,
  CircularProgress,
  Container,
  Menu,
  MenuItem,
  OutlinedInput,
  ThemeProvider,
  Typography,
} from "@mui/material";
import {
  Link,
  Outlet,
  useLoaderData,
  useLocation,
  useNavigation,
  useSearchParams,
} from "@remix-run/react";
import { IconChevronDown } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";

import { GeneralLayout } from "~/components/general-layout";
import { CalendarClockIcon, SparklesFilledIcon } from "~/components/icons";
import { DiscordIcon } from "~/components/icons/discord-icon";
import { Routes } from "~/constants/routes";
import { themeV2 } from "~/utils/theme";

import { LeaderboardAside } from "../components/leaderboard-aside";
import type { ExploreOrder, WisherStats } from "../types";

export function HistoricalLeaderboardLayoutPage() {
  const [params] = useSearchParams();
  const navigation = useNavigation();

  const activeTab = params.get("tab") ?? "monthly";
  const data = useLoaderData() as {
    currentStats: WisherStats;
    orders: {
      recent: ExploreOrder[];
      top1h: ExploreOrder[];
      top24h: ExploreOrder[];
    };
    isHistoricalLeaderboard: boolean;
    monthName: string;
    timeframe: string;
    year: string;
  };

  const isLoading =
    navigation.state === "loading" &&
    navigation.location?.pathname.startsWith(Routes.leaderboard.main());

  return (
    <GeneralLayout>
      <ThemeProvider theme={themeV2}>
        <Box sx={{ bgcolor: "neutral.2" }}>
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { xs: 2, lg: 4 },
              py: { xs: 3, lg: 4 },
              px: { xs: 1.5, lg: 3 },
              minHeight: "100vh",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", lg: "row" },
                gap: { xs: 2, lg: 4 },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  flexGrow: 1,
                  borderRadius: { xs: 2, lg: 3 },
                  borderWidth: { xs: 1, lg: 2 },
                  borderColor: "neutral.0",
                  borderStyle: "solid",
                  bgcolor: "rgba(255, 255, 255, 0.5)",
                  boxShadow: (theme) => theme.palette.shadows.primary[300],
                  px: { xs: 1.5, lg: 3 },
                  py: { xs: 2, lg: 3 },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column",
                      md: "row",
                    },
                    gap: 2,
                    justifyContent: "space-between",
                  }}
                >
                  <LeaderboardSelectTable timeframe={data.timeframe} />
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CalendarClockIcon sx={{ mr: 1 }} />
                    <Typography component="h1" variant="h7" color="neutrals.6">
                      {data.monthName} {data.year}'s Top Rankings
                    </Typography>
                  </Box>
                </Box>
                <Box
                  role="tabpanel"
                  id={`leaderboard-tabpanel-${activeTab}`}
                  aria-labelledby={`leaderboard-tab-${activeTab}`}
                  sx={{ opacity: isLoading ? 0.5 : 1, height: "100%" }}
                >
                  <Outlet />
                </Box>
                {isLoading ? (
                  <Box
                    sx={{
                      position: "absolute",
                      inset: 0,
                      display: "grid",
                      placeContent: "center",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : undefined}
              </Box>
              <LeaderboardAside
                recent={data?.orders.recent || []}
                top1h={data?.orders.top1h || []}
                top24h={data?.orders.top24h || []}
              />
            </Box>
          </Container>
        </Box>
      </ThemeProvider>
    </GeneralLayout>
  );
}

function getLeaderboardTypeOptions(timeframe: string) {
  return [
    {
      label: "WishTender Wishers",
      path: Routes.leaderboard.historicalMain(timeframe),
    },
    {
      label: "Discord Servers",
      path: Routes.leaderboard.historicalDiscordServers(timeframe),
    },
    {
      label: "Discord Wishers",
      path: Routes.leaderboard.historicalDiscordWishers(timeframe),
    },
  ];
}

function LeaderboardSelectTable(props: { timeframe: string }) {
  const location = useLocation();
  const [anchorElement, setAnchorElement] = useState<HTMLElement>();
  const open = Boolean(anchorElement);

  const currentPath = location.pathname.replace(/\/$/, "");

  const selectedOption = getLeaderboardTypeOptions(props.timeframe).find(
    (option) => option.path === currentPath,
  );

  function handleClick(event: React.MouseEvent<HTMLInputElement>) {
    setAnchorElement(event.currentTarget);
  }
  function handleClose() {
    setAnchorElement(undefined);
  }

  useEffect(() => {
    if (location) {
      setAnchorElement(undefined);
    }
  }, [location]);

  return (
    <>
      <OutlinedInput
        readOnly
        value={selectedOption?.label}
        sx={{
          px: 2,
          py: 1.5,
          minWidth: { xs: "100%", md: 242 },
          bgcolor: "neutral.0",
          boxShadow: (theme) => theme.palette.shadows.primary[100],
          "& fieldset": {
            borderColor: "transparent",
            "&.Mui-focused": {
              outline: "1px solid",
              outlineColor: "primary.1",
            },
          },
          cursor: "pointer",
          "& .MuiOutlinedInput-input": {
            padding: 0,
            cursor: "pointer",
          },
        }}
        startAdornment={
          currentPath === Routes.leaderboard.historicalMain(props.timeframe) ? (
            <SparklesFilledIcon sx={{ mr: 1, color: "primary.4" }} />
          ) : (
            <DiscordIcon sx={{ mr: 1, color: "#5865F2" }} />
          )
        }
        endAdornment={<IconChevronDown />}
        onClick={handleClick}
        id="leaderboard-select"
        aria-controls={open ? "leaderboard-select-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      />
      <Menu
        id="leaderboard-select-menu"
        open={open}
        anchorEl={anchorElement}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "leaderboard-select",
        }}
        PaperProps={{
          sx: {
            mt: 1,
            width: anchorElement?.clientWidth ?? 242,
            boxShadow: (theme) => theme.palette.shadows.light[500],
          },
        }}
      >
        {getLeaderboardTypeOptions(props.timeframe).map((option) => (
          <MenuItem
            key={option.path}
            component={Link}
            to={option.path}
            selected={option.path === currentPath}
            preventScrollReset
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
