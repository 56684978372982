import {
  Box,
  CircularProgress,
  Container,
  Menu,
  MenuItem,
  OutlinedInput,
  Stack,
  Tab,
  Tabs,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import {
  Link,
  Outlet,
  useLoaderData,
  useLocation,
  useNavigation,
  useSearchParams,
} from "@remix-run/react";
import { IconChevronDown } from "@tabler/icons-react";
import React, { useEffect } from "react";

import { GeneralLayout } from "~/components/general-layout";
import { SparklesFilledIcon } from "~/components/icons";
import { DiscordIcon } from "~/components/icons/discord-icon";
import { Routes } from "~/constants/routes";
import { useAuth } from "~/hooks/use-auth";
import { themeV2 } from "~/utils/theme";

import { LeaderboardAside } from "../components/leaderboard-aside";
import { LeaderboardWisherCard } from "../components/leaderboard-wisher-card-v2";
import type { ExploreOrder, WisherStats } from "../types";

export function LeaderboardLayoutPage() {
  const auth = useAuth();
  const [params] = useSearchParams();
  const navigation = useNavigation();

  const activeTab = params.get("tab") ?? "monthly";
  const data = useLoaderData() as {
    currentStats?: WisherStats; // currentStats is undefined when current user is not wisher
    orders: {
      recent: ExploreOrder[];
      top1h: ExploreOrder[];
      top24h: ExploreOrder[];
    };
  };

  const isLoading =
    navigation.state === "loading" &&
    navigation.location?.pathname.startsWith(Routes.leaderboard.main());

  return (
    <GeneralLayout>
      <ThemeProvider theme={themeV2}>
        <Box sx={{ bgcolor: "neutral.2" }}>
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { xs: 2, lg: 4 },
              py: { xs: 3, lg: 4 },
              px: { xs: 1.5, lg: 3 },
              minHeight: "calc(100vh - 70px)",
            }}
          >
            {/* {auth.wisher && data.currentStats && (
              <Box
                sx={{
                  display: "grid",
                  gap: { xs: "10px", lg: 3 },
                  gridTemplateColumns: {
                    xs: "repeat(1, minmax(0, 1fr))",
                    md: "repeat(2, minmax(0, 1fr))",
                  },
                }}
              >
                <LeaderboardWisherCard wisher={auth.wisher} stats={data.currentStats} />
                <LeaderboardWisherStats stats={data.currentStats} />
              </Box>
            )} */}

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", lg: "row" },
                gap: { xs: 2, lg: 4 },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  flexGrow: 1,
                  gap: { xs: 2, lg: 4 },
                }}
              >
                {auth.wisher && data.currentStats && (
                  <LeaderboardWisherCard wisher={auth.wisher} stats={data.currentStats} />
                )}
                <Box
                  sx={{
                    borderRadius: { xs: 2, lg: 3 },
                    borderWidth: { xs: 1, lg: 2 },
                    borderColor: "neutral.0",
                    borderStyle: "solid",
                    bgcolor: "rgba(255, 255, 255, 0.5)",
                    boxShadow: (theme) => theme.palette.shadows.primary[300],
                    px: { xs: 1.5, lg: 3 },
                    py: { xs: 2, lg: 3 },
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: {
                        xs: "column",
                        md: "row",
                        gap: "16px",
                        justifyContent: "space-between",
                      },
                    }}
                  >
                    <LeaderboardSelectTable />
                    <LeaderboardSelectTime />
                  </Box>
                  <Box
                    role="tabpanel"
                    id={`leaderboard-tabpanel-${activeTab}`}
                    aria-labelledby={`leaderboard-tab-${activeTab}`}
                    sx={{ opacity: isLoading ? 0.5 : 1, height: "100%" }}
                  >
                    <Outlet />
                  </Box>
                  {isLoading ? (
                    <Box
                      sx={{
                        position: "absolute",
                        inset: 0,
                        display: "grid",
                        placeContent: "center",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  ) : undefined}
                </Box>
              </Box>
              <LeaderboardAside
                recent={data?.orders.recent || []}
                top1h={data?.orders.top1h || []}
                top24h={data?.orders.top24h || []}
              />
            </Box>
          </Container>
        </Box>
      </ThemeProvider>
    </GeneralLayout>
  );
}

const options = [
  {
    label: "WishTender Wishers",
    path: Routes.leaderboard.main(),
  },
  {
    label: "Discord Servers",
    path: Routes.leaderboard.discordServers(),
  },
  {
    label: "Discord Wishers",
    path: Routes.leaderboard.discordWishers(),
  },
];

function LeaderboardSelectTable() {
  const location = useLocation();
  const [anchorElement, setAnchorElement] = React.useState<HTMLElement>();
  const open = Boolean(anchorElement);

  const [params] = useSearchParams();
  const activeTab = params.get("tab");

  const currentPath = location.pathname.replace(/\/$/, "");
  const search = activeTab ? `?tab=${activeTab}` : "";

  const selectedOption = options.find((option) => option.path === currentPath);

  function handleClick(event: React.MouseEvent<HTMLInputElement>) {
    setAnchorElement(event.currentTarget);
  }
  function handleClose() {
    setAnchorElement(undefined);
  }

  useEffect(() => {
    if (location) {
      setAnchorElement(undefined);
    }
  }, [location]);

  return (
    <>
      <OutlinedInput
        readOnly
        value={selectedOption?.label}
        sx={{
          px: 2,
          py: 1.5,
          minWidth: { xs: "100%", md: 242 },
          bgcolor: "neutral.0",
          boxShadow: (theme) => theme.palette.shadows.primary[100],
          "& fieldset": {
            borderColor: "transparent",
            "&.Mui-focused": {
              outline: "1px solid",
              outlineColor: "primary.1",
            },
          },
          cursor: "pointer",
          "& .MuiOutlinedInput-input": {
            padding: 0,
            cursor: "pointer",
          },
        }}
        startAdornment={
          currentPath === Routes.leaderboard.main() ? (
            <SparklesFilledIcon sx={{ mr: 1, color: "primary.4" }} />
          ) : (
            <DiscordIcon sx={{ mr: 1, color: "#5865F2" }} />
          )
        }
        endAdornment={<IconChevronDown />}
        onClick={handleClick}
        id="leaderboard-select"
        aria-controls={open ? "leaderboard-select-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      />
      <Menu
        id="leaderboard-select-menu"
        open={open}
        anchorEl={anchorElement}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "leaderboard-select",
        }}
        PaperProps={{
          sx: {
            mt: 1,
            width: anchorElement?.clientWidth ?? 242,
            boxShadow: (theme) => theme.palette.shadows.light[500],
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.path}
            component={Link}
            to={`${option.path}${search}`}
            selected={option.path === currentPath}
            preventScrollReset
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

const tabOptions = [
  { value: "monthly", label: "Monthly" },
  { value: "weekly", label: "Weekly" },
  { value: "daily", label: "Daily" },
];

function LeaderboardSelectTime() {
  const theme = useTheme();
  const [params] = useSearchParams();

  const activeTab = params.get("tab") ?? "monthly";

  return (
    <Box>
      <Tabs value={activeTab} aria-label="leaderboard tabs" centered>
        {tabOptions.map((tab) => (
          <Tab
            key={tab.value}
            value={tab.value}
            id={`leaderboard-tab-${tab.value}`}
            aria-controls={`leaderboard-tabpanel-${tab.value}`}
            component={Link}
            to={`?tab=${tab.value}`}
            preventScrollReset
            label={
              <Stack direction="row" spacing={1} alignItems="center">
                <Box component="span">{tab.label}</Box>
              </Stack>
            }
            sx={{
              // Only the tab in leaderboard has a different size
              ...theme.typography.b5,
              "&.Mui-selected": {
                ...theme.typography.h5,
              },
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
}
