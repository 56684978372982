import { keyframes } from "@mui/material";

const scroll = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

export const styles = {
  container: {
    overflowX: "hidden",
    display: "flex",
    flexDirection: "row",
    position: "relative",
    width: "var(--width)",
    transform: "var(--transform)",
    "&:hover div": {
      animationPlayState: "var(--pause-on-hover)",
    },
    "&:active div": {
      animationPlayState: "var(--pause-on-click)",
    },
  },
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    "&::before, &::after": {
      background: "linear-gradient(to right, var(--gradient-color), transparent)",
      content: '""',
      height: "100%",
      position: "absolute",
      width: "var(--gradient-width)",
      zIndex: 2,
    },
    "&::after": {
      right: 0,
      top: 0,
      transform: "rotateZ(180deg)",
    },
    "&::before": {
      left: 0,
      top: 0,
    },
  },
  marquee: {
    flex: "0 0 auto",
    minWidth: "var(--min-width)",
    zIndex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    animation: `${scroll} var(--duration) linear var(--delay) var(--iteration-count)`,
    animationPlayState: "var(--play)",
    animationDelay: "var(--delay)",
    animationDirection: "var(--direction)",
  },
  initialChildContainer: {
    flex: "0 0 auto",
    display: "flex",
    minWidth: "auto",
    flexDirection: "row",
    alignItems: "center",
  },
  child: {
    transform: "var(--transform)",
  },
};
