import {
  Avatar,
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link } from "@remix-run/react";
import { forwardRef } from "react";

import { UserAvatar } from "~/components/user-avatar";
import { Routes } from "~/constants/routes";

import type { LeaderboardRecordDiscordWisher } from "../types";
import { leaderboardTableSxStylesNew } from "../utils/styles";
import { LeaderboardTableCellLink } from "./leaderboard-table-cell-link";

export function LeaderboardDiscordWishersTable({
  records,
}: {
  records: LeaderboardRecordDiscordWisher[];
}) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Table sx={leaderboardTableSxStylesNew}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: { xs: 20, md: 85 } }}>Rank</TableCell>
          <TableCell>
            <Box sx={{ pl: { xs: "48px", md: 0 } }}>Wisher</Box>
          </TableCell>
          <TableCell sx={{ width: { xs: 104, md: 160 }, textAlign: { xs: "center", md: "left" } }}>
            Server
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {records.map((record) => {
          const wisher = record.member;
          const link = wisher.isPublic
            ? Routes.public.wisher({ username: wisher.handle })
            : undefined;
          return (
            <TableRow key={`${wisher.id}_${record.rank}`}>
              <TableCell>
                <LeaderboardTableCellLink href={link} tabIndex={-1} />
                <Typography variant="b5">#{record.rank}</Typography>
              </TableCell>
              <TableCell>
                {wisher.isPublic ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "inherit",
                      maxWidth: { xs: "50vw", md: "auto" },
                    }}
                  >
                    <LeaderboardTableCellLink href={link} />
                    <UserAvatar
                      alt={wisher.handle}
                      src={wisher.avatarUrl}
                      sx={{ mr: { xs: 1, md: 2 } }}
                    />
                    <Box className="notranslate" sx={{ overflow: "hidden" }}>
                      <Typography
                        component="div"
                        variant={isMobileScreen ? "h4" : "h5"}
                        noWrap
                        title={wisher.name}
                      >
                        {wisher.name}
                      </Typography>
                      <Typography
                        component="div"
                        variant={isMobileScreen ? "b4" : "b5"}
                        noWrap
                        title={`@${wisher.handle}`}
                      >
                        @{wisher.handle}
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <Box className="anonymous" sx={{ display: "flex", alignItems: "center" }}>
                    <UserAvatar sx={{ mr: { xs: 1, md: 2 } }} />
                    <Typography component="div" variant="h5" className="notranslate">
                      Anonymous
                    </Typography>
                  </Box>
                )}
              </TableCell>
              <TableCell>
                <LeaderboardTableCellLink href={link} tabIndex={-1} />
                <Stack
                  direction="row"
                  sx={{
                    flexWrap: { xs: "wrap", md: "nowrap" },
                    gap: 1,
                    justifyContent: { xs: "center", md: "flex-start" },
                  }}
                >
                  {wisher.discordGuilds.slice(0, 4).map((guild, guildIndex) => (
                    <Tooltip key={`${guild.id}_${guildIndex}`} title={guild.name}>
                      <DiscordGuildAvatar guild={guild} />
                    </Tooltip>
                  ))}
                </Stack>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

const DiscordGuildAvatar = forwardRef(function (
  props: { guild: LeaderboardRecordDiscordWisher["member"]["discordGuilds"][0] },
  ref,
) {
  if (!props.guild.isPublic) {
    return (
      <Box
        {...props}
        ref={ref}
        className="anonymous"
        sx={{ display: "flex", alignItems: "center", width: 40 }}
      >
        <Avatar alt={"anonymous"} src={props.guild.iconUrl} />
      </Box>
    );
  }

  if (props.guild.promotionalUrl) {
    return (
      <Box
        {...props}
        component={Link}
        ref={ref}
        to={props.guild.promotionalUrl}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ display: "flex", alignItems: "center", width: 40 }}
      >
        <Avatar alt={props.guild.name} src={props.guild.iconUrl} />
      </Box>
    );
  }

  return (
    <Box {...props} ref={ref} sx={{ display: "flex", alignItems: "center", width: 40 }}>
      <Avatar alt={props.guild.name} src={props.guild.iconUrl} />
    </Box>
  );
});

DiscordGuildAvatar.displayName = "DiscordGuildAvatar";
