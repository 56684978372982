import { Avatar, Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Link } from "@remix-run/react";

import { MoneyWithOriginal } from "~/components/money";

import type { LeaderboardDiscordGuild } from "../types";
import { leaderboardTableSxStyles } from "../utils/styles";

export function LeaderboardDiscordGuildsTable({ guilds }: { guilds: LeaderboardDiscordGuild[] }) {
  return (
    <Table sx={leaderboardTableSxStyles}>
      <TableHead>
        <TableRow>
          <TableCell>Rank</TableCell>
          <TableCell>Server</TableCell>
          <TableCell>Amount</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {guilds.map((guild, index) => (
          <TableRow key={`${guild.id}_${index}`}>
            <TableCell>#{index + 1}</TableCell>
            <TableCell>
              <DiscordGuildAvatar guild={guild} />
            </TableCell>
            <TableCell>
              <MoneyWithOriginal money={guild.amount} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function DiscordGuildAvatar({ guild }: { guild: Omit<LeaderboardDiscordGuild, "amount"> }) {
  if (!guild.isPublic) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Avatar alt={guild.name} src={guild.iconUrl} sx={{ mr: 2 }} />
        <span className="notranslate">Anonymous server</span>
      </Box>
    );
  }

  if (guild.promotionalUrl) {
    return (
      <Box
        component={Link}
        to={guild.promotionalUrl}
        target="#"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Avatar alt={guild.name} src={guild.iconUrl} sx={{ mr: 2 }} />
        <span className="notranslate">{guild.name}</span>
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Avatar alt={guild.name} src={guild.iconUrl} sx={{ mr: 2 }} />
      <span className="notranslate">{guild.name}</span>
    </Box>
  );
}
