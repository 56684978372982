import type { SxProps, Theme } from "@mui/material";

export const leaderboardTableSxStyles: SxProps<Theme> = {
  "& .MuiTableHead-root": {
    "& .MuiTableCell-root": {
      padding: "6px 16px",
      fontWeight: 300,
    },
  },
  "& .MuiTableBody-root": {
    "& .MuiTableRow-root": {
      "& .MuiTableCell-root": {
        padding: "6px 16px",
      },
      "& .MuiTableCell-root:nth-of-type(2)": {
        fontWeight: 500,
      },
      "& .MuiTableCell-root:nth-of-type(2) .anonymous": {
        fontWeight: 300,
      },
    },

    "& .MuiTableRow-root:first-of-type": {
      "& .MuiTableCell-root:nth-of-type(2)": {
        background: "linear-gradient(90deg, #cdb5ff00, white, 10%, white 80%, #cdb5ff00)",
        fontWeight: 700,
      },
      borderTop: "3px solid #c4a7fd",
      borderBottom: "3px solid #efd4ff",
      background: "#c3a7fd57",
      fontWeight: "bolder",
    },
    "& .MuiTableRow-root:nth-of-type(2)": {
      "& .MuiTableCell-root:nth-of-type(2)": {
        background: "linear-gradient(90deg, #c3a7fd00, white, 10%, white 80%, #c3a7fd00)",
        fontWeight: 900,
      },
      borderBottom: "3px solid #f4e1fffa",
      background: "#c3a7fd38",
      fontWeight: 900,
    },
    "& .MuiTableRow-root:nth-of-type(3)": {
      "& .MuiTableCell-root:nth-of-type(2)": {
        background: "linear-gradient(90deg, #f2ecfe00, white, 10%, white 80%,  #f2ecfe00)",
        fontWeight: 900,
      },
      borderBottom: "2px solid #f6e7fd",
      background: "#f2ecfe8a",
      fontWeight: 700,
    },
  },
};

export const leaderboardTableSxStylesNew: SxProps<Theme> = {
  tableLayout: "auto",
  borderCollapse: "separate",
  borderSpacing: "0 12px",
  "& .MuiTableCell-root": {
    borderBottom: "none",
    padding: {
      xs: "8px 0 8px 8px",
      md: "8px 16px",
    },
    "&:last-of-type": {
      xs: {
        paddingRight: "8px",
      },
      md: {
        paddingRight: "16px",
      },
    },
  },
  "& .MuiTableBody-root": {
    "& .MuiTableRow-root": {
      borderRadius: "8px",
      boxShadow: (theme) => theme.palette.shadows.primary[100],
      "&:focus-within:has(.table-cell-link:focus-visible)": {
        outline: (theme) => `2px solid ${theme.palette.primary[3]}`,
      },
    },
    "& .MuiTableCell-root": {
      position: "relative",
      backgroundColor: "white",

      "& .MuiTypography-noWrap": {
        whiteSpace: "initial",
        display: "-webkit-box",
        WebkitLineClamp: 1,
        WebkitBoxOrient: "vertical",
        wordBreak: "break-all",
      },

      "&:first-of-type": {
        borderTopLeftRadius: "8px",
        borderBottomLeftRadius: "8px",
      },
      "&:last-of-type": {
        borderTopRightRadius: "8px",
        borderBottomRightRadius: "8px",
      },
    },
    "& .MuiTableRow-root:hover .MuiTableCell-root": {
      backgroundColor: "neutral.2",
    },
  },
};
