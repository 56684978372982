import {
  Avatar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { SparklesFilledIcon } from "~/components/icons";
import { MoneyWithOriginal } from "~/components/money";

import type { LeaderboardRecordDiscordGuild } from "../types";
import { leaderboardTableSxStylesNew } from "../utils/styles";
import { LeaderboardTableCellLink } from "./leaderboard-table-cell-link";

export function LeaderboardDiscordGuildsTable({
  records,
}: {
  records: LeaderboardRecordDiscordGuild[];
}) {
  return (
    <Table sx={leaderboardTableSxStylesNew}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: { xs: 20, md: 85 } }}>Rank</TableCell>
          <TableCell>
            <Box sx={{ pl: { xs: "48px", md: 0 } }}>Server</Box>
          </TableCell>
          <TableCell sx={{ width: { xs: 20, md: 160 }, textAlign: { xs: "right", md: "left" } }}>
            <Box sx={{ pl: 3 }}>Amount</Box>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {records.map((record) => {
          const guild = record.member;
          return (
            <TableRow key={`${guild.id}_${record.rank}`}>
              <TableCell>
                <LeaderboardTableCellLink
                  href={guild.promotionalUrl}
                  tabIndex={-1}
                  target="_blank"
                  rel="noopener noreferrer"
                />

                <Typography variant="b5">#{record.rank}</Typography>
              </TableCell>
              <TableCell>
                <LeaderboardTableCellLink
                  href={guild.promotionalUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                />
                <DiscordGuild guild={guild} />
              </TableCell>
              <TableCell>
                <LeaderboardTableCellLink
                  href={guild.promotionalUrl}
                  tabIndex={-1}
                  target="_blank"
                  rel="noopener noreferrer"
                />
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ color: "primary.4", height: "24px", mr: 0.75 }}>
                    <SparklesFilledIcon />
                  </Typography>
                  <MoneyWithOriginal money={record.amount} />
                </Box>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

function DiscordGuild({ guild }: { guild: LeaderboardRecordDiscordGuild["member"] }) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ display: "flex", alignItems: "center", color: "inherit", overflow: "hidden" }}>
      <Avatar alt={guild.name} src={guild.iconUrl} sx={{ mr: { xs: 1, md: 2 } }} />
      <Typography
        variant={isMobileScreen ? "h4" : "h5"}
        component="div"
        className="notranslate"
        noWrap
        title={guild.name}
      >
        {guild.name}
      </Typography>
    </Box>
  );
}
