import { Avatar, Box, Chip, Tooltip, Typography } from "@mui/material";
import type { Theme } from "@mui/material/styles";
import type { SystemStyleObject } from "@mui/system";
import { Link } from "@remix-run/react";
import { IconTrendingUp } from "@tabler/icons-react";
import type { HTMLAttributes } from "react";
import { forwardRef } from "react";

import { BacknoteSparkleIcon } from "~/components/icons";
import { CrowndIcon } from "~/components/icons/crownd-icon";
import { UserAvatar } from "~/components/user-avatar";
import { Routes } from "~/constants/routes";
import { usePopover } from "~/hooks/use-popover";
import { useFormatCurrency } from "~/modules/currency";

import type {
  LeaderboardRecordDiscordGuild,
  LeaderboardRecordDiscordWisher,
  LeaderboardRecordWisher,
} from "../types";
import { WisherPopover } from "./wisher-popover";

export function FeaturedWisherCard({ record }: { record: LeaderboardRecordWisher }) {
  const wisher = record.member;
  const wisherUrl = wisher.isPublic ? Routes.public.wisher({ username: wisher.handle }) : undefined;

  const wisherPopover = usePopover();

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Box
        component={wisherUrl ? Link : "div"}
        to={wisherUrl}
        sx={{
          color: "inherit",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <AvatarTop
          top={record.rank}
          url={wisher.avatarUrl}
          alt={`${wisher.name}'s avatar`}
          {...wisherPopover.anchorProps}
          {...wisherPopover.triggerHoverProps}
        />
        <Box mt={1} />
        <DisplayName name={wisher.name} />
      </Box>

      {wisher.wishlist && (
        <WisherPopover
          id={wisherPopover.id}
          wisher={wisher}
          wishlist={wisher.wishlist}
          open={wisherPopover.open}
          anchorEl={wisherPopover.anchorElement}
          onClose={wisherPopover.handleClose}
          onOpen={wisherPopover.handleOpen}
        />
      )}

      <Chip
        color="secondary"
        icon={<IconTrendingUp size={20} />}
        label={`Top ${wisher.topPercent}%`}
        sx={{
          px: { xs: 1, sm: 3 },
          bgcolor: "blue.2",
          color: "blue.5",
          gap: 1,
          "& .MuiChip-icon": { ml: 0 },
          "& .MuiChip-label": (theme) => ({
            ...theme.typography.sh4,
            px: 0.5,
          }),
        }}
      />
    </Box>
  );
}

export function FeaturedDiscordCard({ record }: { record: LeaderboardRecordDiscordGuild }) {
  const guild = record.member;
  const formatCurrency = useFormatCurrency(record.amount.currency);
  const guildUrl = guild.promotionalUrl;

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Box
        component={guildUrl ? Link : "div"}
        to={guildUrl}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          color: "inherit",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <AvatarTop top={record.rank} url={guild.iconUrl} alt={`${guild.name}'s avatar`} />
        <Box mt={1} />
        <DisplayName name={guild.name} />
      </Box>
      <Chip
        color="secondary"
        icon={
          <BacknoteSparkleIcon
            sx={{ width: 20, height: 20, display: { xs: "none", lg: "flex" } }}
          />
        }
        label={formatCurrency(record.amount.amount, { decimalPlacesThreshold: 100_000 })}
        sx={{
          px: { xs: 1, sm: 3 },
          bgcolor: "green.2",
          color: "green.5",
          gap: 1,
          "& .MuiChip-icon": { ml: 0 },
          "& .MuiChip-label": (theme) => ({
            ...theme.typography.bh6,
            px: 0.5,
          }),
        }}
      />
    </Box>
  );
}

export function FeaturedDiscordWisherCard({ record }: { record: LeaderboardRecordDiscordWisher }) {
  const wisher = record.member;
  const wisherUrl = wisher.handle ? Routes.public.wisher({ username: wisher.handle }) : undefined;

  const wisherPopover = usePopover();

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Box
        component={wisherUrl ? Link : "div"}
        to={wisherUrl}
        sx={{
          color: "inherit",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <AvatarTop
          top={record.rank}
          url={wisher.avatarUrl}
          alt={`${wisher.name}'s avatar`}
          {...wisherPopover.anchorProps}
          {...wisherPopover.triggerHoverProps}
        />
        <Box mt={1} />
        <DisplayName name={wisher.name} />
      </Box>

      {wisher.wishlist && (
        <WisherPopover
          id={wisherPopover.id}
          wisher={wisher}
          wishlist={wisher.wishlist}
          open={wisherPopover.open}
          anchorEl={wisherPopover.anchorElement}
          onClose={wisherPopover.handleClose}
          onOpen={wisherPopover.handleOpen}
        />
      )}

      <Box
        sx={{
          display: "flex",
          gap: { xs: 1, sm: 2 },
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          mb: 1,
        }}
      >
        {wisher.discordGuilds.slice(0, 4).map((guild, index) => {
          const guildName = guild.isPublic ? guild.name : "Anonymous server";
          const guildUrl =
            guild.isPublic && guild.promotionalUrl ? guild.promotionalUrl : undefined;
          return (
            <Tooltip key={`${guild.id}_${index}`} title={guild.name}>
              <Box
                component={guildUrl ? Link : "div"}
                to={guildUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Avatar
                  src={guild.iconUrl}
                  alt={guildName}
                  sx={{ width: { xs: 40, sm: 48 }, height: { xs: 40, sm: 48 } }}
                />
              </Box>
            </Tooltip>
          );
        })}
      </Box>
    </Box>
  );
}

const avatarStyles: Record<number, SystemStyleObject<Theme>> = {
  1: {
    background:
      "linear-gradient(2.92deg, #007EFF -4.71%, #02D6F2 28.23%, #01AEF8 56%, #D06DFF 83.76%)",
    width: { xs: 80 + 6, sm: 104 + 8 },
    height: { xs: 80 + 6, sm: 104 + 8 },
    p: { xs: "3px", sm: "4px" },
    mt: "38px", // The height of the crown icon
  },
  2: {
    background: "linear-gradient(2.85deg, #01AEF8 -4.78%, #3D67FF 101.55%)",
    width: { xs: 64 + 6, sm: 92 + 6 },
    height: { xs: 64 + 6, sm: 92 + 6 },
  },
  3: {
    background: "linear-gradient(2.85deg, #46C4C4 -4.78%, #00B0D7 101.55%)",
    width: { xs: 56 + 6, sm: 84 + 6 },
    height: { xs: 56 + 6, sm: 84 + 6 },
  },
};

type AvatarTopProps = HTMLAttributes<HTMLDivElement> & {
  top: number;
  url?: string;
  alt?: string;
};
const AvatarTop = forwardRef<HTMLDivElement, AvatarTopProps>(function (
  { top, url, alt, ...props },
  ref,
) {
  return (
    <Box
      sx={{
        position: "relative",
        padding: "3px",
        borderRadius: 999,
        ...avatarStyles[top],

        "& .MuiAvatar-root": {
          border: "1px solid white",
          width: "100%",
          height: "100%",
        },
      }}
      ref={ref}
      {...props}
    >
      {top === 1 && (
        <Box
          sx={{
            position: "absolute",
            bottom: "100%",
            left: 0,
            right: 0,
            display: "flex",
            justifyContent: "center",
            transform: "translateY(6px)",
            zIndex: 0,
          }}
        >
          <CrowndIcon sx={{ width: 50, height: 38 }} />
        </Box>
      )}
      <UserAvatar src={url} alt={alt} />
    </Box>
  );
});
AvatarTop.displayName = "AvatarTop";

function DisplayName({ name }: { name: string }) {
  return (
    <Box
      sx={{
        textAlign: "center",
        wordBreak: "break-word",
        overflow: "hidden",
      }}
      className="notranslate"
    >
      <Typography
        title={name}
        sx={{
          typography: { xs: "h4", sm: "bh5" },
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
        }}
      >
        {name}
      </Typography>
    </Box>
  );
}
