import { useRouteLoaderData } from "@remix-run/react";

import type { LegacyNotification } from "~/types";

export function useTopRankNotifications() {
  const { legacyNotification } = useRouteLoaderData("root") as {
    legacyNotification: LegacyNotification;
  };
  return legacyNotification.newNotifications.filter((notification) => notification.type === "top");
}
