import { Badge, Box, Typography } from "@mui/material";
import { Link } from "@remix-run/react";
import { useEffect, useId, useRef, useState } from "react";

import { SegmentedTabs } from "~/components/segmented-tabs";
import { UserAvatar } from "~/components/user-avatar";
import { Routes } from "~/constants/routes";
import { useScreen } from "~/hooks/use-screen";
import { useFormatCurrency } from "~/modules/currency";
import type { Money } from "~/types";

import type { ExploreOrder } from "../types";

type LeaderboardAsideProps = {
  recent: ExploreOrder[];
  top1h: ExploreOrder[];
  top24h: ExploreOrder[];
};

export function LeaderboardAside({ recent, top1h, top24h }: LeaderboardAsideProps) {
  const [tab, setTab] = useState("last1h");
  const tabId = useId();
  const sidebarRef = useRef<HTMLDivElement>(null);
  const [topOffset, setTopOffset] = useState(0);

  const screen = useScreen();

  const maxRecent = screen === "tablet" ? 12 : 7;

  useEffect(() => {
    if (sidebarRef.current) {
      const { height } = sidebarRef.current.getBoundingClientRect();
      const screenHeight = window.innerHeight;
      const offset = Math.min(-(height - screenHeight + 24), 70 + 24);
      setTopOffset(offset);
    }
  }, []);

  return (
    <Box
      sx={{
        flexShrink: 0,
        width: { xs: "100%", lg: "320px" },
      }}
    >
      <Box
        ref={sidebarRef}
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row", lg: "column" },
          gap: { xs: 3, lg: 2 },
          position: "sticky",
          top: topOffset,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography variant="h7" color="neutral.6" sx={{ px: 1 }}>
            Recently Active Wishers
          </Typography>
          <Box sx={{ pb: 1, pt: { xs: 1, lg: 2 } }}>
            {recent.slice(0, maxRecent).map((order) => (
              <WisherActiveRow key={order.id} wisher={order.wisher} />
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            px: 1,
            pb: { xs: 1.5, lg: 2 },
            pt: { xs: 1, lg: 1.5 },
            borderRadius: 3,
            border: "2px solid #fff",
            bgcolor: "rgba(255, 255, 255, 0.80)",
            boxShadow: (theme) => theme.palette.shadows.primary[100],
          }}
        >
          <Typography variant="h7" color="neutral.6" sx={{ px: 1 }}>
            Largest Gifts
          </Typography>
          <Box mt={1} />
          <SegmentedTabs.Root
            value={tab}
            onChange={(_, value) => setTab(value)}
            variant="fullWidth"
          >
            <SegmentedTabs.Tab value="last1h" label="Last hour" tabId={tabId} />
            <SegmentedTabs.Tab value="last24h" label="Last 24 hrs" tabId={tabId} />
          </SegmentedTabs.Root>
          <Box mt={2} />
          <SegmentedTabs.TabPanel tab="last1h" selectedTab={tab} tabId={tabId}>
            {top1h.map((order, index) => (
              <WisherProfitRow
                key={order.id}
                wisher={order.wisher}
                profit={order.price}
                rank={index + 1}
              />
            ))}
          </SegmentedTabs.TabPanel>
          <SegmentedTabs.TabPanel tab="last24h" selectedTab={tab} tabId={tabId}>
            {top24h.map((order, index) => (
              <WisherProfitRow
                key={order.id}
                wisher={order.wisher}
                profit={order.price}
                rank={index + 1}
              />
            ))}
          </SegmentedTabs.TabPanel>
        </Box>
      </Box>
    </Box>
  );
}

function WisherActiveRow({ wisher }: { wisher: ExploreOrder["wisher"] }) {
  return (
    <Box
      component={Link}
      sx={{
        display: "flex",
        gap: 2,
        alignItems: "center",
        p: 1,
        borderRadius: 2,
        ":hover": { bgcolor: "white", boxShadow: (theme) => theme.palette.shadows.primary[300] },
      }}
      to={Routes.public.wisher({ username: wisher.handle })}
    >
      <Badge
        overlap="circular"
        badgeContent=" "
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        variant="dot"
        sx={{
          "& .MuiBadge-badge": {
            bgcolor: "green.4",
            border: "2px solid white",
            width: 16,
            height: 16,
            borderRadius: 999,
            boxShadow: (theme) => theme.palette.shadows.primary[100],
          },
        }}
      >
        <UserAvatar
          sx={{
            width: 48,
            height: 48,
          }}
          src={wisher.avatarUrl}
          alt={wisher.name}
        />
      </Badge>
      <Box className="notranslate" sx={{ overflow: "hidden" }}>
        <Typography variant="h5" color="neutral.6" component="div" noWrap title={wisher.name}>
          {wisher.name}
        </Typography>
        <Typography
          variant="b5"
          color="neutral.6"
          component="div"
          noWrap
          title={`@${wisher.handle}`}
        >
          @{wisher.handle}
        </Typography>
      </Box>
    </Box>
  );
}

function WisherProfitRow({
  rank,
  wisher,
  profit,
}: {
  rank: number;
  wisher: ExploreOrder["wisher"];
  profit?: Money;
}) {
  const formatCurrency = useFormatCurrency(profit?.currency || "USD");

  return (
    <Box
      component={Link}
      sx={{
        display: "flex",
        gap: 2,
        alignItems: "center",
        p: 1,
        borderRadius: 2,
        ":hover": { bgcolor: "neutral.2" },
      }}
      to={Routes.public.wisher({ username: wisher.handle })}
    >
      <Box sx={{ flexShrink: 0 }}>
        <Badge
          overlap="circular"
          badgeContent={<Typography variant="h4">{rank}</Typography>}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          sx={{
            "& .MuiBadge-badge": {
              bgcolor: "primary.4",
              border: "2px solid white",
              color: "white",
              width: 24,
              height: 24,
              maxHeight: 24,
              borderRadius: 999,
              boxShadow: (theme) => theme.palette.shadows.primary[100],
            },
          }}
        >
          <UserAvatar
            sx={{
              width: 48,
              height: 48,
            }}
            src={wisher.avatarUrl}
            alt={wisher.name}
          />
        </Badge>
      </Box>
      <Box className="notranslate" sx={{ flexGrow: 1, overflow: "hidden" }}>
        <Typography variant="h5" color="neutral.6" component="div" noWrap title={wisher.name}>
          {wisher.name}
        </Typography>
        <Typography
          variant="b5"
          color="neutral.6"
          component="div"
          noWrap
          title={`@${wisher.handle}`}
        >
          @{wisher.handle}
        </Typography>
      </Box>
      {profit && (
        <Box
          sx={{
            borderRadius: 1,
            px: 0.75,
            bgcolor: "green.2",
            color: "green.6",
            display: "flex",
            alignItems: "center",
            flexShrink: 0,
          }}
        >
          <Typography variant="sh3">
            +{formatCurrency(profit.amount, { style: "compact" })}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
