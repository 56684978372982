import { SvgIcon, type SvgIconProps } from "@mui/material";

export function CrowndIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      width="50"
      height="38"
      viewBox="0 0 50 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_333_41678)">
        <path
          d="M46.0548 23.2858L43.9498 35.9063C43.1977 35.5246 42.4328 35.1655 41.6553 34.829C38.6398 33.5241 35.4376 32.5642 32.1026 32.0001C29.8208 31.6127 27.474 31.412 25.082 31.412C22.8355 31.412 20.6301 31.5887 18.4798 31.9308C14.8366 32.509 11.3517 33.558 8.08877 35.0128C8.08736 35.0142 8.08595 35.0142 8.08595 35.0142C7.40311 35.3182 6.73158 35.6405 6.06995 35.9798L3.95357 23.2858C1.66755 23.085 -0.048733 21.0521 0.2015 18.7109C0.413561 16.7303 2.04643 15.1667 4.03274 15.0366C6.44742 14.8754 8.45352 16.7868 8.45352 19.1647C8.45211 19.5987 8.38283 20.0299 8.2457 20.4413L11.6033 22.5747C13.8116 23.9813 16.7239 23.5148 18.3822 21.4875L21.672 17.464C21.1489 16.7599 20.8662 15.9046 20.8648 15.0267C20.8648 12.7421 22.7182 10.8887 25.0028 10.8887C27.2874 10.8887 29.1422 12.7421 29.1422 15.0267C29.1408 15.9046 28.8581 16.7599 28.335 17.464L31.6248 21.4875C33.2831 23.5162 36.1968 23.9828 38.4065 22.5747L41.7613 20.4413C41.6256 20.0299 41.5563 19.5987 41.5549 19.1647C41.5549 16.8801 43.4069 15.0267 45.6929 15.0267C47.8305 15.0309 49.6147 16.6638 49.8055 18.7943C50.0105 21.0704 48.331 23.0822 46.0548 23.2858Z"
          fill="url(#paint0_linear_333_41678)"
        />
        <path
          d="M43.9498 35.9063L43.6204 37.8827C43.619 37.9223 43.6161 37.9619 43.6119 38C42.6732 37.5236 41.7147 37.0839 40.7363 36.681C37.2628 35.2446 33.5517 34.2649 29.6795 33.8154C28.2459 33.7008 26.6866 33.6358 25.0028 33.6358C22.6956 33.6358 20.6202 33.7574 18.7852 33.9624C15.7329 34.4021 12.7881 35.1725 9.98601 36.2357C8.75606 36.7008 7.55438 37.2239 6.3838 37.8007C6.38097 37.8021 6.37673 37.8035 6.3739 37.8063L6.06995 35.9798C6.73158 35.6405 7.40311 35.3182 8.08594 35.0142C8.08594 35.0142 8.08736 35.0142 8.08877 35.0128C11.3517 33.558 14.8366 32.5091 18.4798 31.9308C20.6301 31.5887 22.8355 31.412 25.082 31.412C27.474 31.412 29.8208 31.6127 32.1026 32.0001C35.4376 32.5642 38.6398 33.5241 41.6553 34.829C42.4328 35.1655 43.1977 35.5246 43.9498 35.9063Z"
          fill="#CF6AFF"
        />
        <path
          d="M29.138 4.07441C26.9933 3.32654 26.121 2.45426 25.3732 0.30961C25.229 -0.103203 24.6479 -0.103203 24.5037 0.30961C24.0386 1.64418 23.5254 2.48536 22.687 3.11165C22.6644 3.12861 22.6404 3.14416 22.6164 3.16113C22.5626 3.1993 22.5075 3.23747 22.451 3.27423C22.4184 3.29543 22.3845 3.31664 22.352 3.33785C22.2997 3.37036 22.246 3.40288 22.1908 3.43398C22.1583 3.45236 22.1258 3.47215 22.0919 3.49053C22.007 3.53719 21.9208 3.58242 21.8303 3.62766C21.8119 3.63756 21.7907 3.64604 21.7724 3.65453C21.6932 3.6927 21.6112 3.73087 21.5278 3.76763C21.4924 3.78318 21.4557 3.79873 21.4189 3.81428C21.3454 3.84538 21.2705 3.87507 21.1941 3.90617C21.1588 3.92031 21.1234 3.93445 21.0867 3.94858C20.9792 3.98958 20.8676 4.03058 20.753 4.07017C20.7488 4.07158 20.7446 4.07299 20.7417 4.07441C20.3289 4.21861 20.3289 4.79966 20.7417 4.94386C22.8864 5.69173 23.7587 6.56401 24.5065 8.70866C24.5786 8.91506 24.7596 9.01827 24.942 9.01827C25.1229 9.01827 25.3053 8.91506 25.3774 8.70866C25.4198 8.58708 25.4622 8.46974 25.5061 8.35664C25.5174 8.32695 25.5287 8.29867 25.54 8.2704C25.5739 8.18416 25.6078 8.10075 25.6432 8.01875C25.6559 7.99048 25.6672 7.96079 25.6799 7.93251C25.7252 7.82931 25.7704 7.72752 25.8185 7.63138C25.8213 7.62714 25.8241 7.62149 25.8256 7.61725C25.8694 7.52677 25.916 7.44053 25.9613 7.3557C25.9768 7.32743 25.9924 7.30057 26.0079 7.27229C26.0433 7.21009 26.08 7.14788 26.1182 7.08851C26.1352 7.06165 26.1507 7.0362 26.1677 7.00934C26.8109 6.02113 27.6804 5.45281 29.1422 4.94386C29.555 4.79966 29.555 4.21861 29.1422 4.07441H29.138Z"
          fill="#0CB4E9"
        />
        <path
          d="M28.0989 25.1052C26.4844 24.5426 25.8284 23.8866 25.2658 22.2721C25.1569 21.9611 24.7201 21.9611 24.6112 22.2721C24.0485 23.8866 23.3926 24.5426 21.7781 25.1052C21.467 25.2141 21.467 25.6509 21.7781 25.7598C23.3926 26.3225 24.0485 26.9784 24.6112 28.5929C24.7201 28.904 25.1569 28.904 25.2658 28.5929C25.8284 26.9784 26.4844 26.3225 28.0989 25.7598C28.4099 25.6509 28.4099 25.2141 28.0989 25.1052Z"
          fill="white"
        />
        <path
          d="M11.2966 29.362C12.1164 29.362 12.781 28.6974 12.781 27.8776C12.781 27.0578 12.1164 26.3932 11.2966 26.3932C10.4767 26.3932 9.81213 27.0578 9.81213 27.8776C9.81213 28.6974 10.4767 29.362 11.2966 29.362Z"
          fill="white"
        />
        <path
          d="M38.5818 29.362C39.4017 29.362 40.0663 28.6974 40.0663 27.8776C40.0663 27.0578 39.4017 26.3932 38.5818 26.3932C37.762 26.3932 37.0974 27.0578 37.0974 27.8776C37.0974 28.6974 37.762 29.362 38.5818 29.362Z"
          fill="white"
        />
        <path
          d="M14.1948 20.0313C15.0146 20.0313 15.6792 19.3667 15.6792 18.5469C15.6792 17.727 15.0146 17.0624 14.1948 17.0624C13.3749 17.0624 12.7103 17.727 12.7103 18.5469C12.7103 19.3667 13.3749 20.0313 14.1948 20.0313Z"
          fill="#7388FC"
        />
        <path
          d="M35.6836 20.0313C36.5035 20.0313 37.1681 19.3667 37.1681 18.5469C37.1681 17.727 36.5035 17.0624 35.6836 17.0624C34.8638 17.0624 34.1992 17.727 34.1992 18.5469C34.1992 19.3667 34.8638 20.0313 35.6836 20.0313Z"
          fill="#7388FC"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_333_41678"
          x1="-1.17402"
          y1="10.8887"
          x2="51.6711"
          y2="31.5286"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#007EFF" />
          <stop offset="0.364583" stopColor="#02D6F2" />
          <stop offset="0.671875" stopColor="#01AEF8" />
          <stop offset="0.979167" stopColor="#CF69FF" />
        </linearGradient>
        <clipPath id="clip0_333_41678">
          <rect width="49.645" height="38" fill="white" transform="translate(0.17749)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
