import { Avatar, Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Link } from "@remix-run/react";

import { Routes } from "~/constants/routes";

import type { LeaderboardWisher } from "../types";
import { leaderboardTableSxStyles } from "../utils/styles";

export function LeaderboardWishersTable({ wishers }: { wishers: LeaderboardWisher[] }) {
  return (
    <Table sx={leaderboardTableSxStyles}>
      <TableHead>
        <TableRow>
          <TableCell>Rank</TableCell>
          <TableCell>Handle</TableCell>
          <TableCell>Top</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {wishers.map((wisher, index) => (
          <TableRow key={`${wisher.handle}_${index}`}>
            <TableCell>#{index + 1}</TableCell>
            <TableCell>
              {wisher.isPublic ? (
                <Box
                  component={Link}
                  to={Routes.public.wisher({ username: wisher.handle })}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Avatar alt={wisher.handle} src={wisher.avatarUrl} sx={{ mr: 2 }} />
                  <span className="notranslate">{`@${wisher.handle}`}</span>
                </Box>
              ) : (
                <Box className="anonymous" sx={{ display: "flex", alignItems: "center" }}>
                  <Avatar alt={"anonymous"} sx={{ mr: 2 }} />
                  <span>Anonymous</span>
                </Box>
              )}
            </TableCell>
            <TableCell>{Math.round(wisher.topPercent * 100) / 100}%</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
