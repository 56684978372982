import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { SparklesFilledIcon } from "~/components/icons";
import { UserAvatar } from "~/components/user-avatar";
import { Routes } from "~/constants/routes";

import type { LeaderboardRecordWisher } from "../types";
import { leaderboardTableSxStylesNew } from "../utils/styles";
import { LeaderboardTableCellLink } from "./leaderboard-table-cell-link";

export function LeaderboardWishersTable({ records }: { records: LeaderboardRecordWisher[] }) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Table sx={leaderboardTableSxStylesNew}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: { xs: 20, md: 85 } }}>Rank</TableCell>
          <TableCell>
            <Box sx={{ pl: { xs: "48px", md: 0 } }}>Wisher</Box>
          </TableCell>
          <TableCell sx={{ width: { xs: 20, md: 160 } }}>
            <Box sx={{ pl: 3 }}>Top</Box>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {records.map((record) => {
          const wisher = record.member;
          const link = wisher.isPublic
            ? Routes.public.wisher({ username: wisher.handle })
            : undefined;
          return (
            <TableRow key={`${wisher.handle}_${record.rank}`}>
              <TableCell>
                <LeaderboardTableCellLink href={link} tabIndex={-1} />
                <Typography variant="b5">#{record.rank}</Typography>
              </TableCell>
              <TableCell>
                {wisher.isPublic ? (
                  <Box sx={{ display: "flex", alignItems: "center", color: "inherit" }}>
                    <LeaderboardTableCellLink href={link} />
                    <UserAvatar
                      alt={wisher.handle}
                      src={wisher.avatarUrl}
                      sx={{ mr: { xs: 1, md: 2 } }}
                    />
                    <Box className="notranslate" sx={{ overflow: "hidden" }}>
                      <Typography
                        component="div"
                        variant={isMobileScreen ? "h4" : "h5"}
                        noWrap
                        title={wisher.name}
                      >
                        {wisher.name}
                      </Typography>
                      <Typography
                        component="div"
                        variant={isMobileScreen ? "b4" : "b5"}
                        noWrap
                        title={`@${wisher.handle}`}
                      >
                        @{wisher.handle}
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <Box className="anonymous" sx={{ display: "flex", alignItems: "center" }}>
                    <UserAvatar sx={{ mr: { xs: 1, md: 2 } }} />
                    <Typography
                      component="div"
                      variant={isMobileScreen ? "h4" : "h5"}
                      className="notranslate"
                    >
                      Anonymous
                    </Typography>
                  </Box>
                )}
              </TableCell>
              <TableCell>
                <LeaderboardTableCellLink href={link} tabIndex={-1} />
                <Box sx={{ display: "flex" }}>
                  <Typography sx={{ color: "primary.4", height: "24px", mr: 0.75 }}>
                    <SparklesFilledIcon />
                  </Typography>
                  <Typography variant={isMobileScreen ? "b4" : "b5"}>
                    {Math.round(wisher.topPercent * 100) / 100}%
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
