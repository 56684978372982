import { Avatar, Badge, Box, Stack, Tooltip, Typography } from "@mui/material";
import { Link } from "@remix-run/react";

import { Marquee } from "~/components/marquee";
import { MoneyWithOriginal } from "~/components/money";
import { Routes } from "~/constants/routes";
import { useAuth } from "~/hooks/use-auth";

import type { ExploreOrder } from "../types";

type OrdersMarqueeProps = {
  label: string;
  orders: ExploreOrder[];
  speed?: number;
  showBadge?: boolean;
};

export function OrdersMarquee({ label, orders, speed = 1, showBadge }: OrdersMarqueeProps) {
  return (
    <Box>
      <Typography
        variant="sh4"
        sx={{
          display: "inline-block",
          color: "#fff",
          background: "#0bb4e1",
          padding: "2px 7px 2.5px 7px",
          borderRadius: "5px 5px 0px 0px",
        }}
      >
        {label}
      </Typography>
      <Box
        sx={{
          overflow: "hidden",
          background: "#0000008c",
          paddingTop: 0.5,
          minHeight: 58,
          borderRadius: "0px 10px 10px 10px",
          "& .rfm-marquee-container": {
            paddingBottom: "5px",
          },
        }}
      >
        <Marquee gradient={false} loop={0} speed={speed || 15} pauseOnHover>
          <Stack direction="row" spacing={2} pr={2}>
            {/* duplicate the array to solve bug that leads to space */}
            {orders.map((order, index) => {
              return (
                <OrderItem key={order.id} order={order} place={index + 1} showBadge={showBadge} />
              );
            })}

            {orders.map((order, index) => {
              return (
                <OrderItem key={order.id} order={order} place={index + 1} showBadge={showBadge} />
              );
            })}
          </Stack>
        </Marquee>
      </Box>
    </Box>
  );
}

const TOP_COLORS: Record<number, string> = {
  1: "linear-gradient(to left, rgb(0 126 255), rgb(2, 214, 242))",
  2: "linear-gradient(to left, rgb(152 199 247), rgb(177 244 253))",
  3: "linear-gradient(to left, rgb(177 244 253), rgb(152 199 247))",
};

function OrderItem({
  order,
  place,
  showBadge,
}: {
  order: ExploreOrder;
  place: number;
  showBadge?: boolean;
}) {
  const { user } = useAuth();
  return (
    <Link to={Routes.public.wisher({ username: order.wisher.handle })}>
      <Tooltip
        title={
          <span>
            <span>{order.wisher.handle} </span>
            {order.date && <span>{new Date(order.date)?.toTimeString()?.slice(0, 5)} </span>}
            {user?.admin && order.price && <MoneyWithOriginal money={order.price} />}
          </span>
        }
      >
        <Badge
          badgeContent={`#${place}`}
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          invisible={!showBadge}
          sx={{
            "& .MuiBadge-badge": {
              backgroundColor: "#ffe057",
              color: "#000000de",
            },
          }}
        >
          <Box
            sx={{
              p: showBadge && place <= 3 ? 0.5 : 0,
              display: "inline-block",
              borderRadius: "50%",
              background: showBadge ? TOP_COLORS[place] : "transparent",
              backgroundClip: "padding-box",
              width: 45,
              height: 45,
            }}
          >
            <Avatar
              src={order.wisher.avatarUrl}
              alt={order.wisher.handle}
              sx={{
                width: "100%",
                height: "100%",
              }}
            />
          </Box>
        </Badge>
      </Tooltip>
    </Link>
  );
}
