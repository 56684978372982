import { Avatar, Box, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Link } from "@remix-run/react";

import { MoneyWithOriginal } from "~/components/money";
import { Routes } from "~/constants/routes";

import type { LeaderboardWisher } from "../types";
import { leaderboardTableSxStyles } from "../utils/styles";

export function LeaderboardWishersTableFull({ wishers }: { wishers: LeaderboardWisher[] }) {
  return (
    <Table sx={leaderboardTableSxStyles}>
      <TableHead>
        <TableRow>
          <TableCell>Rank</TableCell>
          <TableCell>Handle</TableCell>
          <TableCell>Anonymous</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Net Profit</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {wishers.map((wisher, index) => (
          <TableRow key={`${wisher.handle}_${index}`}>
            <TableCell>#{index + 1}</TableCell>
            <TableCell>
              <Box
                component={Link}
                to={Routes.public.wisher({ username: wisher.handle })}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Avatar alt={wisher.handle} src={wisher.avatarUrl} sx={{ mr: 2 }} />
                <span className="notranslate">{`@${wisher.handle}`}</span>
              </Box>
            </TableCell>
            <TableCell>
              {wisher.isPublic ? (
                <span>Public</span>
              ) : (
                <span style={{ color: "red" }}>Anonymous</span>
              )}
            </TableCell>
            <TableCell>{wisher.email}</TableCell>
            <TableCell>{wisher.profit && <MoneyWithOriginal money={wisher.profit} />}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
