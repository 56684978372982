import {
  Avatar,
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Link } from "@remix-run/react";
import { forwardRef } from "react";

import { Routes } from "~/constants/routes";

import type { LeaderboardDiscordGuild, LeaderboardDiscordWisher } from "../types";
import { leaderboardTableSxStyles } from "../utils/styles";

export function LeaderboardDiscordWishersTable({
  wishers,
}: {
  wishers: LeaderboardDiscordWisher[];
}) {
  return (
    <Table sx={leaderboardTableSxStyles}>
      <TableHead>
        <TableRow>
          <TableCell>Rank</TableCell>
          <TableCell>Wisher</TableCell>
          <TableCell>Discord Servers</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {wishers.map((wisher, index) => (
          <TableRow key={`${wisher.id}_${index}`}>
            <TableCell>#{index + 1}</TableCell>
            <TableCell>
              {wisher.isPublic ? (
                <Box
                  component={Link}
                  to={Routes.public.wisher({ username: wisher.handle })}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Avatar alt={wisher.handle} src={wisher.avatarUrl} sx={{ mr: 2 }} />
                  <span className="notranslate">@{wisher.handle}</span>
                </Box>
              ) : (
                <Box className="anonymous" sx={{ display: "flex", alignItems: "center" }}>
                  <Avatar alt={"anonymous"} sx={{ mr: 2 }} />
                  <span>Anonymous</span>
                </Box>
              )}
            </TableCell>
            <TableCell>
              <Stack direction="row" sx={{ flexWrap: "wrap", gap: 1 }}>
                {wisher.discordGuilds.map((guild, guildIndex) => (
                  <Tooltip key={`${guild.id}_${guildIndex}`} title={guild.name}>
                    <DiscordGuildAvatar guild={guild} />
                  </Tooltip>
                ))}
              </Stack>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

const DiscordGuildAvatar = forwardRef(function (
  props: { guild: Omit<LeaderboardDiscordGuild, "amount"> },
  ref,
) {
  if (!props.guild.isPublic) {
    return (
      <Box
        {...props}
        ref={ref}
        className="anonymous"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Avatar alt={"anonymous"} src={props.guild.iconUrl} sx={{ mr: 2 }} />
      </Box>
    );
  }

  if (props.guild.promotionalUrl) {
    return (
      <Box
        {...props}
        component={Link}
        ref={ref}
        to={props.guild.promotionalUrl}
        target="#"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Avatar alt={props.guild.name} src={props.guild.iconUrl} sx={{ mr: 2 }} />
      </Box>
    );
  }

  return (
    <Box {...props} ref={ref} sx={{ display: "flex", alignItems: "center" }}>
      <Avatar alt={props.guild.name} src={props.guild.iconUrl} sx={{ mr: 2 }} />
    </Box>
  );
});

DiscordGuildAvatar.displayName = "DiscordGuildAvatar";
