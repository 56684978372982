import { Box, Button, ThemeProvider, Typography } from "@mui/material";
import { IconBrandTwitter, IconStar } from "@tabler/icons-react";
import { useCallback, useEffect, useState } from "react";

import { Dialog } from "~/components/dialog";
import { Routes } from "~/constants/routes";
import { useAuth } from "~/hooks/use-auth";
import { useOrigin } from "~/hooks/use-origin";
import { useWtFetcher } from "~/hooks/use-wt-fetcher";
import { confettiFireWorks } from "~/modules/notifications/components/confetti-fire-works";
import { useTopRankNotifications } from "~/modules/notifications/hooks/use-top-rank-notifications";
import type { Notification } from "~/modules/notifications/types";
import { themeV2 } from "~/utils/theme";

export function LegacyLeaderboardAlert() {
  const { wisher } = useAuth();
  if (!wisher) return <></>;
  return <LegacyLeaderboardAlertDialog />;
}

export function LegacyLeaderboardAlertDialog() {
  const origin = useOrigin();
  const { wisher } = useAuth();
  const [notification, setNotification] = useState<Notification>();
  const [markSeen, setMarkSeen] = useState(false);
  const [confettiInterval, setConfettiInterval] = useState<ReturnType<typeof setInterval>>();
  const notifications = useTopRankNotifications();
  const firstNotification = notifications[0];

  const [submit] = useWtFetcher();

  const handleMarkSeen = useCallback(() => {
    if (!notification) return;
    submit.submit(
      {
        id: notification.id,
      },
      {
        method: "POST",
        action: Routes.actions.notifications.markSeen(),
      },
    );
    setMarkSeen(true);
  }, [submit, notification]);

  function handleClose() {
    if (!markSeen) return;
    setNotification(undefined);
    setMarkSeen(false);
    if (confettiInterval) clearInterval(confettiInterval);
  }

  useEffect(() => {
    if (!notification && firstNotification) {
      setTimeout(() => {
        setNotification(firstNotification);
      }, 200);
    }
  }, [notification, firstNotification]);

  useEffect(() => {
    if (notification && !markSeen) {
      setConfettiInterval(confettiFireWorks());
      setTimeout(() => handleMarkSeen(), 1500);
    }
  }, [notification, markSeen, handleMarkSeen]);

  useEffect(() => {
    return () => {
      if (confettiInterval) clearInterval(confettiInterval);
    };
  }, [confettiInterval]);

  if (!notification || !wisher) return <></>;
  return (
    <ThemeProvider theme={themeV2}>
      <Dialog.Root
        open={!!notification}
        maxWidth="xs"
        variant="normal"
        fullWidth
        onClose={handleClose}
        sx={{
          "& .MuiIconButton-root[aria-label='close']": {
            bgcolor: "rgba(255, 255, 255, 0.5)",
          },
        }}
      >
        <Dialog.Content
          sx={{
            background: "linear-gradient(45deg, rgb(0 114 255), #00c4ff)",
            py: 8,
            px: 4,
            height: { xs: "100vh", lg: "auto" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 3,
              height: "100%",
            }}
          >
            <Typography
              variant="h8"
              sx={{ color: "white", textAlign: "center", textShadow: "0px 2px 4px #0000006b" }}
            >
              {notification.message}
            </Typography>
            <Box sx={{ color: "#ffff00" }}>
              <IconStar size={60} />
            </Box>
            <Box>
              <Button
                variant="text"
                aria-label="Share on Twitter"
                size="medium"
                endIcon={<IconBrandTwitter size={20} />}
                onClick={(event) => {
                  // format: top_1_1-22 (top_<rank>_<month>-<year>)
                  const [, rank, date] = notification?.code?.split("_") ?? [];
                  const month = getMonthName(Number(date.split("-")[0]));
                  const wishlistUrl = `${origin}${Routes.public.wisher({
                    username: wisher?.handle || "",
                  })}`;
                  const params = new URLSearchParams({
                    text: `I was the #${rank} top wisher on WishTender in all of ${month}. \n\n🥳🥳🥳\n\nCheckout my wishlist here: ${wishlistUrl} via @wishtender`,
                  });
                  const shareUrl = `https://twitter.com/intent/tweet?${params.toString()}`;
                  event.stopPropagation();
                  window.open(shareUrl, "popup", "width=600,height=600");
                }}
                sx={{ color: "white" }}
              >
                Share
              </Button>
            </Box>
          </Box>
        </Dialog.Content>
      </Dialog.Root>
    </ThemeProvider>
  );
}

function getMonthName(month: number) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[month - 1];
}
