import { Box, Chip, Typography } from "@mui/material";
import { IconHash, IconPercentage, IconStarFilled } from "@tabler/icons-react";

import { LightBulbIconFilled, SparklesFilledIcon } from "~/components/icons";
import { UserAvatar } from "~/components/user-avatar";
import type { Wisher } from "~/types";

import type { WisherStats } from "../types";

type LeaderboardUserCardProps = {
  wisher: Pick<Wisher, "avatarUrl" | "handle" | "name">;
  stats: WisherStats;
};

export function LeaderboardWisherCard({ wisher, stats }: LeaderboardUserCardProps) {
  const hasMonthlyRank = stats.monthlyRank > -1;
  const hasWeeklyRank = stats.weeklyRank > -1;
  const hasDailyRank = stats.dailyRank > -1;
  return (
    <Box
      sx={{
        position: "relative",
        border: "2px solid",
        borderColor: "neutral.0",
        background: "linear-gradient(108deg, #FFF 7.39%, rgba(255, 255, 255, 0.70) 93.56%);",
        borderRadius: 4,
        boxShadow: (theme) => theme.palette.shadows.primary[300],
        p: 2,
        pr: { xs: 2, sm: 4 },
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: { xs: "start", lg: "center" },
          gap: { xs: 1.5, lg: 2 },
          flexWrap: "wrap",
          flexDirection: { xs: "column", lg: "row" },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: { xs: 1.5, lg: 2 }, mr: "auto" }}>
          <Box
            sx={{
              width: 64,
              height: 64,
              background: "linear-gradient(to left, #3585FE, #34DAFF)",
              borderRadius: 999,
              padding: "2px",
              flexShrink: 0,
            }}
          >
            <UserAvatar
              src={wisher.avatarUrl}
              alt={wisher.handle}
              sx={{ width: "100%", height: "100%" }}
            />
          </Box>
          <Box
            className="notranslate"
            sx={{ pr: 2, display: "grid", gridTemplateRows: "auto 1fr" }}
          >
            <Typography
              variant="h6"
              color="neutral.7"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {wisher.name}
            </Typography>
            <Typography variant="b5" color="neutral.6">
              @{wisher.handle}
            </Typography>
          </Box>
        </Box>
      </Box>
      {hasMonthlyRank ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              gap: { xs: 1.5, lg: 2 },
              mr: "auto",
            }}
          >
            <Chip
              sx={{
                bgcolor: "primary.1",
                color: "primary.6",
                "& .MuiChip-icon": { color: "primary.6", ml: 1.5 },
              }}
              icon={<IconStarFilled size={16} />}
              label={<Typography variant="sh4">#{stats.monthlyRank} wisher this month</Typography>}
            />
            <Chip
              sx={{
                bgcolor: "blue.1",
                color: "blue.6",
                "& .MuiChip-icon": { color: "blue.4", ml: 1.5 },
              }}
              icon={<IconPercentage size={16} />}
              label={<Typography variant="sh4">Top {stats.topPercent}% this month</Typography>}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { lg: 2 },
              flexWrap: "wrap",
            }}
          >
            <Chip
              sx={{
                bgcolor: "transparent",
                mr: { xs: 2, lg: 0 },
                "& .MuiChip-icon": { color: "primary.4", ml: { xs: 0, lg: 1.5 } },
              }}
              icon={<IconHash size={16} />}
              label={
                <Typography color="neutral.6" variant="h4">
                  {hasDailyRank ? `${stats.dailyRank} Daily rank` : "Daily rank: unavailable"}
                </Typography>
              }
            />
            <Chip
              sx={{
                bgcolor: "transparent",
                "& .MuiChip-icon": { color: "primary.4", ml: { xs: 0, lg: 1.5 } },
              }}
              icon={<IconHash size={16} />}
              label={
                <Typography color="neutral.6" variant="h4">
                  {hasWeeklyRank ? `${stats.weeklyRank} Weekly rank` : "Weekly rank: unavailable"}
                </Typography>
              }
            />
          </Box>
        </>
      ) : (
        <Chip
          sx={{
            border: (theme) => `1px solid ${theme.palette.primary[4]}`,
            px: 2,
            py: 2,
            borderRadius: 2,
            bgcolor: "transparent",
            color: "primary.4",
            justifyContent: "start",
            height: "auto",
          }}
          icon={<LightBulbIconFilled />}
          label={
            <Typography sx={{ whiteSpace: "normal" }} variant="h5" color="primary.4" component="p">
              If you have received at least one gift this month, your ranked stats will appear here.
            </Typography>
          }
        />
      )}
      <Box sx={{ position: "absolute", top: 16, right: 16, color: "primary.4" }}>
        <SparklesFilledIcon />
      </Box>
    </Box>
  );
}
