import type { TabProps, TabsProps } from "@mui/material";
import { Tab as BaseTab, Tabs, useTheme } from "@mui/material";
import type { HTMLAttributes } from "react";

function Root({ sx, ...props }: TabsProps) {
  return (
    <Tabs
      sx={{
        borderRadius: 2,
        padding: "2px",
        border: "1px solid",
        borderColor: "neutral.2",
        bgcolor: "rgba(237, 239, 245, 0.80)",
        boxShadow: (theme) => theme.palette.shadows.primary[100],
        "& .MuiTabs-indicator": {
          zIndex: 0,
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#fff",
          borderRadius: 1.5,
          border: "1px solid #fff",
          height: "100%",
          boxShadow: (theme) => theme.palette.shadows.primary[200],
        },
        "& .MuiTabs-flexContainer": {
          position: "relative",
          zIndex: 1,
          marginBottom: 0,
        },
        ...sx,
      }}
      {...props}
    />
  );
}

function Tab({ sx, tabId, ...props }: TabProps & { tabId: string }) {
  const theme = useTheme();
  return (
    <BaseTab
      sx={{
        ...theme.typography.h5,
        px: 1.5,
        py: "6px",
        mb: 0,
        color: "neutral.5",
        ":hover": {
          color: "neutral.5",
        },
        "&.Mui-selected": {
          ...theme.typography.h5,
          color: "neutral.7",
          ":hover": {
            color: "neutral.7",
          },
        },
        "&.Mui-focusVisible": {
          bgcolor: "neutral.1",
        },
        ...sx,
      }}
      id={`${tabId}-tab-${props.value}`}
      aria-controls={`${tabId}-panel-${props.value}`}
      {...props}
    />
  );
}

function TabPanel(
  props: {
    tabId: string;
    tab: string;
    selectedTab: string;
  } & HTMLAttributes<HTMLDivElement>,
) {
  const { children, selectedTab, tab, tabId, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={selectedTab !== tab}
      id={`${tabId}-panel-${tab}`}
      aria-labelledby={`${tabId}-tab-${tab}`}
      {...other}
    >
      {selectedTab === tab && children}
    </div>
  );
}

export const SegmentedTabs = {
  Root,
  Tab,
  TabPanel,
};
