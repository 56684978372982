import { useLocation } from "@remix-run/react";
import type { RefObject } from "react";
import { useIntersectionObserver } from "usehooks-ts";

import { Pagination } from "~/components/pagination";
import { useScreen } from "~/hooks/use-screen";

export function LeaderboardPagination({
  page,
  totalPage,
  // useIntersectionObserver doesn't accept undefined
  // eslint-disable-next-line unicorn/no-null
  anchorRef = { current: null },
}: {
  page: number;
  totalPage: number;
  anchorRef?: RefObject<HTMLElement>; // It will scroll to this element when page changed if it's not visible on the screen
}) {
  const location = useLocation();
  const screen = useScreen();
  const isMobileScreen = screen === "mobile";

  const entry = useIntersectionObserver(anchorRef, { threshold: 0.5 });

  function scrollToFirstItemIfNotVisible() {
    if (entry?.isIntersecting === false) {
      window.scrollTo({
        top: anchorRef.current?.offsetTop || 0,
        behavior: "smooth",
      });
    }
  }

  return (
    <Pagination
      count={totalPage}
      page={page || 1}
      url={`${location.pathname}${location.search}`}
      sx={{
        "& .MuiPagination-ul": {
          justifyContent: "center",
        },
      }}
      shape="rounded"
      boundaryCount={isMobileScreen ? 0 : 1}
      showFirstButton={isMobileScreen}
      showLastButton={isMobileScreen}
      hideNextButton={isMobileScreen}
      hidePrevButton={isMobileScreen}
      preventScrollReset
      onChange={scrollToFirstItemIfNotVisible}
    />
  );
}
