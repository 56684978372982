import { Box } from "@mui/material";
import { useLoaderData, useSearchParams } from "@remix-run/react";
import { useRef } from "react";

import type { LeaderboardRecordWisher } from "..";
import { FeaturedWisherCard } from "../components/featured-card";
import { LeaderboardWishersTable } from "../components/leaderboard-new-wishers-table";
import { LeaderboardPagination } from "../components/leaderboard-pagination";
import { LeaderboardPodium } from "../components/leaderboard-podium";

export function LeaderboardIndexPage() {
  const data = useLoaderData() as { leaderboard: LeaderboardRecordWisher[]; totalPage: number };
  const records = data?.leaderboard || [];
  const [params] = useSearchParams();
  const page = Number(params.get("page"));

  const paginationAnchorRef = useRef<HTMLDivElement>(null);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box mt={2} />
      <LeaderboardPodium
        fistPlace={records[0] ? <FeaturedWisherCard record={records[0]} /> : <></>}
        secondPlace={records[1] ? <FeaturedWisherCard record={records[1]} /> : <></>}
        thirdPlace={records[2] ? <FeaturedWisherCard record={records[2]} /> : <></>}
      />
      <Box mt={1.5} ref={paginationAnchorRef} />
      <LeaderboardWishersTable records={records.slice(3)} />
      <Box mb="auto" />
      {data.totalPage > 1 && (
        <>
          <Box mt={2} />
          <LeaderboardPagination
            totalPage={data.totalPage}
            page={page || 1}
            anchorRef={paginationAnchorRef}
          />
        </>
      )}
    </Box>
  );
}
