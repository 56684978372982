import { Box, Fade, Paper, Popper, Typography, useControlled } from "@mui/material";
import { Link } from "@remix-run/react";

import { Button } from "~/components/button";
import { UserAvatar } from "~/components/user-avatar";
import { Routes } from "~/constants/routes";
import type { Wisher, Wishlist, WishlistItem } from "~/types";

type WisherPopoverProps = {
  id: string;
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose?: () => void;
  onOpen?: () => void;

  wisher: Pick<Wisher, "id" | "name" | "handle" | "avatarUrl"> & { bio?: string };
  wishlist?: Pick<Wishlist, "id" | "name"> & {
    items: Pick<WishlistItem, "id" | "title" | "thumbnail">[];
  };
};
export function WisherPopover({
  id,
  open: value,
  onClose,
  onOpen,
  wisher,
  wishlist,
  anchorEl,
}: WisherPopoverProps) {
  const [open, setOpen] = useControlled({
    controlled: value,
    default: false,
    name: "WisherPopover",
  });

  const wisherUrl = Routes.public.wisher({ username: wisher.handle });

  function handleOpen() {
    setOpen(true);
    onOpen?.();
  }

  function handleClose() {
    setOpen(false);
    onClose?.();
  }

  return (
    <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom" transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper
            sx={{
              overflowX: "auto",
              borderRadius: "16px",
              background: "#ffffff",
              boxShadow: "0px 36px 48px 0px rgba(52, 87, 140, 0.20)",
              mt: 1,
            }}
            onMouseEnter={handleOpen}
            onMouseLeave={handleClose}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: 400,
                px: 2,
                pt: 2,
                pb: 2.5,
              }}
            >
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <UserAvatar
                  src={wisher.avatarUrl}
                  alt={wisher.name}
                  sx={{
                    width: 64,
                    height: 64,
                    border: "2px solid #ffffff",
                    boxShadow: (theme) => theme.palette.shadows.light[200],
                    flexShrink: 0,
                  }}
                />
                <Box className="notranslate" sx={{ mr: "auto", overflow: "hidden" }}>
                  <Typography variant="bh6" color="neutral.6" noWrap component="div">
                    {wisher.name}
                  </Typography>
                  <Typography variant="b5" color="neutral.5" noWrap component="div">
                    @{wisher.handle}
                  </Typography>
                </Box>
                <Box sx={{ flexShrink: 0 }}>
                  <Button size="sm" variant="subtle" component={Link} to={wisherUrl}>
                    Wishlist
                  </Button>
                </Box>
              </Box>
              <Typography variant="b5" component="div">
                {wisher.bio}
              </Typography>
              {wishlist && wishlist.items.length > 0 && (
                <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 2 }}>
                  {wishlist.items?.slice(0, 3).map((item) => (
                    <Box
                      key={item.id}
                      sx={{
                        width: 112,
                        height: 112,
                        borderRadius: "8px",
                        overflow: "hidden",
                        bgcolor: "neutral.4",
                        "& img": {
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        },
                      }}
                      component={Link}
                      to={Routes.public.wish({ username: wisher.handle, wishId: item.id })}
                      title={item.title}
                    >
                      <img src={item.thumbnail} alt={item.title} loading="lazy" />
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
}
