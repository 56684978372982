import IframeResizer from "iframe-resizer-react";

export function TestimonialEmbed() {
  return (
    <IframeResizer
      src="https://embed-v2.testimonial.to/w/wishtender?theme=dark&card=base&loadMore=on&initialCount=20"
      style={{ width: "1px", minWidth: "100%" }}
    />
  );
}
