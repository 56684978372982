import { Box } from "@mui/material";
import { useLoaderData, useSearchParams } from "@remix-run/react";
import { useRef } from "react";

import type { LeaderboardRecordDiscordWisher } from "..";
import { FeaturedDiscordWisherCard } from "../components/featured-card";
import { LeaderboardDiscordWishersTable } from "../components/leaderboard-new-discord-wishers-table";
import { LeaderboardPagination } from "../components/leaderboard-pagination";
import { LeaderboardPodium } from "../components/leaderboard-podium";

export function LeaderboardDiscordWishersPage() {
  const data = useLoaderData() as {
    leaderboard: LeaderboardRecordDiscordWisher[];
    totalPage: number;
  };
  const records = data?.leaderboard || [];
  const [params] = useSearchParams();
  const page = Number(params.get("page"));

  const paginationAnchorRef = useRef<HTMLDivElement>(null);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box mt={2} />
      <LeaderboardPodium
        fistPlace={records[0] ? <FeaturedDiscordWisherCard record={records[0]} /> : <></>}
        secondPlace={records[1] ? <FeaturedDiscordWisherCard record={records[1]} /> : <></>}
        thirdPlace={records[2] ? <FeaturedDiscordWisherCard record={records[2]} /> : <></>}
      />
      <Box mt={1.5} ref={paginationAnchorRef} />
      <LeaderboardDiscordWishersTable records={records.slice(3)} />
      <Box mb="auto" />
      {data.totalPage > 1 && (
        <>
          <Box mt={2} />
          <LeaderboardPagination
            totalPage={data.totalPage}
            page={page || 1}
            anchorRef={paginationAnchorRef}
          />
        </>
      )}
    </Box>
  );
}
