import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import type { ReactNode } from "react";

import firstPodiumImage from "../images/first-podium.png";
import firstPodiumImageSmall from "../images/first-podium-sm.png";
import secondPodiumImage from "../images/second-podium.png";
import secondPodiumImageSmall from "../images/second-podium-sm.png";
import thirdPodiumImage from "../images/third-podium.png";
import thirdPodiumImageSmall from "../images/third-podium-sm.png";

type LeaderboardPodiumProps = {
  fistPlace?: ReactNode;
  secondPlace?: ReactNode;
  thirdPlace?: ReactNode;
};

export function LeaderboardPodium(props: LeaderboardPodiumProps) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const variant = isMobileScreen ? "bh10" : "bh11";
  return (
    <Box sx={{ maxWidth: { xs: 327, sm: 560 }, mx: "auto" }}>
      <Box
        sx={{
          width: "100%",
          display: "grid",
          gridTemplateAreas: "'top2 top1 top3'",
          gridAutoColumns: "minmax(0, 1fr)",
          alignItems: "flex-end",
          "& > div": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            px: 1,
          },
        }}
      >
        <Box sx={{ gridArea: "top1" }}>
          <Box>{props.fistPlace}</Box>
          <Box
            sx={{
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPositionY: "bottom",
              backgroundImage: {
                xs: `url(${firstPodiumImageSmall})`,
                sm: `url(${firstPodiumImage})`,
              },
              height: { xs: 128, sm: 170 },
              width: { xs: 100, sm: 160 },
              mt: 1,
              pt: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
            }}
          >
            <Typography variant={variant}>1</Typography>
          </Box>
        </Box>
        <Box sx={{ gridArea: "top2" }}>
          <Box>{props.secondPlace}</Box>
          <Box
            sx={{
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPositionY: "bottom",
              backgroundImage: {
                xs: `url(${secondPodiumImageSmall})`,
                sm: `url(${secondPodiumImage})`,
              },
              height: { xs: 72, sm: 110 },
              width: { xs: 100, sm: 160 },
              mt: 1,
              pt: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
            }}
          >
            <Typography variant={variant}>2</Typography>
          </Box>
        </Box>
        <Box sx={{ gridArea: "top3" }}>
          <Box>{props.thirdPlace}</Box>
          <Box
            sx={{
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPositionY: "bottom",
              backgroundImage: {
                xs: `url(${thirdPodiumImageSmall})`,
                sm: `url(${thirdPodiumImage})`,
              },
              height: { xs: 50, sm: 78 },
              width: { xs: 100, sm: 160 },
              mt: 1,
              pt: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
            }}
          >
            <Typography variant={variant}>3</Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: 8,
          borderRadius: 8,
          background: "linear-gradient(90deg, #3291E9 0%, #54C3E5 100%)",
        }}
      />
    </Box>
  );
}
