import { useId, useRef, useState } from "react";

import { useScreen } from "./use-screen";

export function usePopover() {
  const screen = useScreen();
  const id = useId();
  const [open, setOpen] = useState(false);

  // eslint-disable-next-line unicorn/no-null -- null is needed for Popover anchorEl
  const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(null);

  // Timeout to close popover, helpful when user moves mouse from trigger to popover
  const closeTimeout = useRef<ReturnType<typeof setTimeout>>();

  function handleOpen() {
    if (closeTimeout.current) clearTimeout(closeTimeout.current);
    setOpen(true);
  }

  function handleClose() {
    if (closeTimeout.current) clearTimeout(closeTimeout.current);
    closeTimeout.current = setTimeout(() => {
      setOpen(false);
    }, 200);
  }

  return {
    id,
    open,
    handleOpen,
    handleClose,
    // Anchor
    anchorElement,
    anchorProps: {
      "aria-describedby": id,
      ref: setAnchorElement,
    },
    // Trigger with hover
    triggerHoverProps: {
      onMouseEnter: () => {
        if (screen !== "desktop") return;
        handleOpen();
      },
      onMouseLeave: handleClose,
    },
  };
}
