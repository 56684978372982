import type { SxProps } from "@mui/material";
import { Box } from "@mui/material";
import { Link } from "@remix-run/react";
import type { AnchorHTMLAttributes } from "react";

type Props = AnchorHTMLAttributes<HTMLAnchorElement> & {
  href?: string;
  sx?: SxProps;
};

export function LeaderboardTableCellLink({ href, sx, ...props }: Props) {
  if (!href) return <></>;
  return (
    <Box
      component={Link}
      to={href}
      className="table-cell-link" // Using to style the table row if this link is focused
      sx={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        "&:focus, &:focus-visible": {
          outline: "none",
        },
        ...sx,
      }}
      {...props}
    />
  );
}
